import React from 'react';
import './FadeIn.scss';

type Props = {};
type State = {
  visible: boolean;
};

export class FadeIn extends React.PureComponent<Props, State> {
  state: State = {
    visible: false,
  };

  componentDidMount = () => setTimeout(() => this.setState({ visible: true }), 0);

  render() {
    return (
      <div className={`FadeIn ${this.state.visible ? 'visible' : ''}`}>
        {this.props.children}
      </div>
    );
  }
}
