import React from 'react';
import './BusinessCard.scss';

export class BusinessCard extends React.PureComponent {
  render() {
    return (
      <div className="BusinessCard">
        <h1>Anton Ivanov</h1>
        <h2>Developer in Hong Kong</h2>
        <p className="email">anton@ivanov.hk</p>

        <div className="skills">
          <p className="level1">
            TypeScript, Node.js, JavaScript, React
          </p>
          <p className="level2">
            React Native, TypeORM, C#, Vue.js
          </p>
          <p className="level3">
            Python, PHP, AWS, Azure
          </p>
        </div>
      </div>
    );
  }
}
