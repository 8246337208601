import React from 'react';
import './App.scss';
import { BusinessCard } from './components/BusinessCard/BusinessCard';
import { CenteredContainer } from './components/CenteredContainer/CenteredContainer';
import { FadeIn } from './components/FadeIn/FadeIn';

function App() {
  return (
    <div className="App">
      <CenteredContainer>
        <FadeIn>
          <BusinessCard />
        </FadeIn>
      </CenteredContainer>
    </div>
  );
}

export default App;
