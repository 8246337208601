import React from 'react';
import './CenteredContainer.scss';

export class CenteredContainer extends React.PureComponent {
  render() {
    return (
      <div className="CenteredContainer">
        {this.props.children}
      </div>
    );
  }
}
